import React from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Collapsible from "react-collapsible";
import { useTranslation } from "react-i18next";
const Sidebar = ({ show, handleClose }) => {
  const { t } = useTranslation("usertable");
  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="sidebar_sub_menu text-white mt-3">
              <li>
                <NavLink to="/">{t("home")}</NavLink>
              </li>
              <li>
                <NavLink to="/about">{t("aboutus")}</NavLink>
              </li>
              <Collapsible
                trigger={t("ourproducts")}
                triggerTagName="div"
                triggerOpenedClassName="icon_close"
                triggerClassName="iconAdd"
                open={false}
              >
                <ul className="sidebar_sub_menu text-white mt-3">
                  <li>
                    <NavLink to="/doctors">CELL MARQUE</NavLink>
                  </li>
                  <li>
                    <NavLink to="/doctorsTwo">DREAMPATH</NavLink>
                  </li>
                  <li>
                    <NavLink to="/doctorDetails">VISIA IMAGING</NavLink>
                  </li>
                </ul>
              </Collapsible>
              <li>
                <NavLink to="/services">{t("ourservices")}</NavLink>
              </li>
              <li>
                <NavLink to="/refferences">{t("ourreferences")}</NavLink>
              </li>
              <li>
                <NavLink to="/contactUs">{t("contactUs")}</NavLink>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
