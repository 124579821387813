import React from "react";
import { Link } from "react-router-dom";

const HomeThreeSingleBlog = ({ image, title, icerik }) => {
  return (
    <>
      <div className="col-xl-4 col-lg-4 col-md-6">
        <div className="latest-news-box latest-news-box-2 latest-news-box-3 mb-30">
          <div className="latest-news-thumb">
            <Link to="/#">
              <img src={`${image}`} alt="" />
            </Link>
          </div>
          <div className="latest-news-content-box pl-0 pr-0">
            <div className="latest-news-content">
              <h3>
                <Link to="/#">{title}</Link>
              </h3>
              <p>{icerik.replace(/(<([^>]+)>)/gi, "")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeThreeSingleBlog;
