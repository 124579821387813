import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DoctorsTwoTeamArea from "../../DoctorsTwo/DoctorsTwoTeamArea/DoctorsTwoTeamArea";
const DoctorDetailsArea = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="doctor-details-area pt-115 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <article className="doctor-details-box">
                <div className="section-title pos-rel mb-25">
                  <div className="section-text pos-rel">
                    <h1>Visia Imaging</h1>
                  </div>
                  <div className="section-line pos-rel">
                    <img src="img/shape/section-title-line.png" alt="" />
                  </div>
                </div>
                <div className="service-details-text mb-40">
                  <p>{t("visiatext")}</p>
                </div>
              </article>
              <DoctorsTwoTeamArea></DoctorsTwoTeamArea>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorDetailsArea;
