import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../../components/Shared/Footer";
import HomeFact from "../../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";
import CounterArea from "../DoctorsOne/CounterArea/CounterArea";
import DoctorsTwoTeamArea from "./DoctorsTwoTeamArea/DoctorsTwoTeamArea";
import { useTranslation } from "react-i18next";

const DoctorsTwo = () => {
  const { t } = useTranslation();
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title={t("kvkkbas")} subtitle="Doctor" />
      <div className="container pt-120 pb-120">
        <h3>{t("kvkkbas")}</h3>
        <p>{t("kvkktext")}</p>
      </div>
      <Footer />
    </>
  );
};

export default DoctorsTwo;
