import React, { useState } from "react";
import CommonSectionArea from "../../../components/CommonSectionArea/CommonSectionArea";

import "react-modal-video/scss/modal-video.scss";

const AboutArea = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <CommonSectionArea
        area_header={
          <div className="about-left-side pos-rel mb-30">
            <div className="about-front-img pos-rel">
              <img src="img/anatomical-banner1.webp" alt="" />
            </div>
            <div className="about-shape">
              <img src="img/about/about-shape.png" alt="" />
            </div>
          </div>
        }
      />
    </>
  );
};

export default AboutArea;
