import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../../components/Shared/Footer";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";
import ServicesDetailsArea from "./ServicesDetailsArea/ServicesDetailsArea";
import { useTranslation } from "react-i18next";

const ServicesDetails = () => {
  const { t } = useTranslation();
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title={t("ourservices")} subtitle="Details" />
      <ServicesDetailsArea />
      <Footer />
    </>
  );
};

export default ServicesDetails;
