import React from "react";
import { Link } from "react-router-dom";
import HomeSingleTeam from "../../../../components/HomeSingleTeam/HomeSingleTeam";
import { useTranslation } from "react-i18next";

const DoctorOneTeamArea = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="team-area pt-115 pb-55">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-10">
              <div className="section-title pos-rel mb-75">
                <div className="section-icon">
                  <img
                    className="section-back-icon back-icon-left"
                    src="img/staff.png"
                    style={{ width: "140px", opacity: "0.1" }}
                    alt=""
                  />
                </div>
                <div className="section-text pos-rel">
                  <h3>{t("ourreferences")}</h3>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <HomeSingleTeam
              image="/img/27380063.jpg"
              title="Ankara Üniversitesi Tıp Fakültesi"
            />
            <HomeSingleTeam
              image="/img/83089713.jpg"
              title="İstanbul Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/33971484.jpg"
              title="Cerrahpaşa Tıp Fakültesi"
            />
            <HomeSingleTeam
              image="/img/20798062.jpg"
              title="Roche Diagnostics"
            />
            <HomeSingleTeam
              image="/img/51261010.jpg"
              title="Acıbadem Sağlık Grubu"
            />
            <HomeSingleTeam
              image="/img/38673451.jpg"
              title="Ege Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/27010838.jpg"
              title="Türk Patoloji Derneği"
            />
            <HomeSingleTeam
              image="/img/122796473.jpg"
              title="Gazi Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/15864387.jpg"
              title="Hacettepe Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/84137107.jpg"
              title="Akdeniz Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/97984929.jpg"
              title="Çukurova Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/51788474.jpg"
              title="19 Mayıs Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/18827606.jpg"
              title="Ankara Atatürk Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/108206434.jpg"
              title="Celal Bayar Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/120027285.jpg"
              title="Ali Osman Sönmez Onkoloji Hastanesi"
            />
            <HomeSingleTeam
              image="/img/104906014.jpg"
              title="Ankara Onkoloji Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/70677348.jpg"
              title="Ankara Numune Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/53506501.jpg"
              title="Ankara Çocuk Sağlığı"
            />
            <HomeSingleTeam image="/img/20664313.jpg" title="Bezmi Alem" />
            <HomeSingleTeam
              image="/img/94970847.jpg"
              title="Trakya Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/48109411.jpg"
              title="Adıyaman Üniversitesi Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/118294188.jpg"
              title="Antalya Özel Sağlık Hizmetleri Merkezi"
            />
            <HomeSingleTeam
              image="/img/53764582.jpg"
              title="Bağcılar Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/44332390.jpg"
              title="Bayer Türk Kimya San. Ltd. Şti."
            />
            <HomeSingleTeam
              image="/img/17345055.jpg"
              title="Bilkent üniversitesi"
            />
            <HomeSingleTeam
              image="/img/10092421.jpg"
              title="Cumhuriyet Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/46527018.jpg"
              title="Çukurova Dr. Aşkım Tüfekçi Devlet Hastanesi"
            />
            <HomeSingleTeam
              image="/img/89142366.jpg"
              title="Denizli Devlet Hastanesi"
            />
            <HomeSingleTeam
              image="/img/94744791.jpg"
              title="Dicle Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/79016935.jpg"
              title="Dr. Lütfü Kırdar Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/42371353.jpg"
              title="Erciyes Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/3994557.jpg"
              title="Eskişehir Devlet Hastanesi"
            />
            <HomeSingleTeam
              image="/img/6754325.jpg"
              title="Eskişehir Osmangazi Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/97973626.jpg"
              title="Eskişehir Yunus Emre Devlet Hastanesi"
            />
            <HomeSingleTeam
              image="/img/68185079.jpg"
              title="Florence Nightingale Hastanesi"
            />
            <HomeSingleTeam
              image="/img/69618652.jpg"
              title="Gaziantep Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/27800151.jpg"
              title="Gaziosmanpaşa Taksim Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/98783660.jpg"
              title="Hatay Kamu Hastaneleri Birliği"
            />
            <HomeSingleTeam
              image="/img/50839039.jpg"
              title="Antakya Devlet Hastanesi"
            />
            <HomeSingleTeam
              image="/img/33628632.jpg"
              title="Haydarpaşa Numune Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/115362994.jpg"
              title="Kartal Yavuz Selim Devlet Hastanesi"
            />
            <HomeSingleTeam
              image="/img/96952606.jpg"
              title="İstanbul Patoloji Grubu"
            />
            <HomeSingleTeam
              image="/img/64136791.jpg"
              title="Van Yüzüncü Yıl Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/96378046.jpg"
              title="Necmettin Erbakan Üniversitesi Meram Tıp Fakültesi"
            />
            <HomeSingleTeam
              image="/img/103965997.jpg"
              title="Özel A Patoloji Laboratuvarı Sağlık Hizmetleri"
            />
            <HomeSingleTeam
              image="/img/15071307.jpg"
              title="Pamukkale Üniversitesi Sağlık Uygulama ve Araştırma Merkezi"
            />
            <HomeSingleTeam
              image="/img/122966015.jpg"
              title="Selçuk Üniversitesi Selçuklu Tıp Fakültesi"
            />
            <HomeSingleTeam
              image="/img/42789556.jpg"
              title="Süleyman Demirel Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/24518570.jpg"
              title="Mersin Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/5341474.jpg"
              title="Dışkapı Yıldırım Beyazıt Hastanesi"
            />
            <HomeSingleTeam
              image="/img/4216845.jpg"
              title="Fırat Üniversitesi Hastanesi"
            />
            <HomeSingleTeam
              image="/img/101895700.jpg"
              title="Marmara Üniversitesi Pendik Eğitim  ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/69256962.jpg"
              title="Dokuz Eylül Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/12980288.jpg"
              title="Ankara İli Birinci Bölge Kamu Hastaneler Birliği Ankara Yüksek İhtisas Hastanesi"
            />
            <HomeSingleTeam
              image="/img/123195839.jpg"
              title="Ankara İli Birinci Bölge Kamu Hastaneler Birliği Ankara Atatürk Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/3661124.jpg"
              title="Sakarya Üniversitesi Eğitim ve Araştırma Hastanesi "
            />
            <HomeSingleTeam
              image="/img/122400875.jpg"
              title="Okmeydanı Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/90114408.jpg"
              title="Dumlupınar Üniversitesi Evliya Çelebi Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/54955144.jpg"
              title="Uludağ Üniversitesi"
            />
            <HomeSingleTeam
              image="/img/47314447.jpg"
              title="İzmir Tepecik Eğitim ve Araştırma Hastanesi"
            />
            <HomeSingleTeam
              image="/img/96329068.jpg"
              title="Tokat Devlet Hastanesi"
            />
            <HomeSingleTeam
              image="/img/46997968.jpg"
              title="Tuzlalı Sağlık Hizmetleri San. Ve Tic. Ltd. Şti"
            />
            <HomeSingleTeam
              image="/img/7330768.jpg"
              title="Yeditepe Üniversitesi Hastanesi"
            />
            <HomeSingleTeam
              image="/img/97272852.jpg"
              title="Uşak Devlet Hastanesi"
            />
            <HomeSingleTeam
              image="/img/120228852.jpg"
              title="Zeynep Kamil Kadın ve Çocuk Hastalıkları Eğitim ve Araş. H."
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorOneTeamArea;
