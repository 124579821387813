import React from "react";
import { Link } from "react-router-dom";

const HomeSIngleService = ({
  icon,
  title,
  border_class,
  text,
  action,
  url,
}) => {
  return (
    <>
      <div className="col-xl-4 col-lg-6 col-md-6">
        <div
          className={
            border_class
              ? `service-box ${border_class} text-center mb-30`
              : "service-box text-center mb-30"
          }
        >
          <div className="service-thumb">
            <img src={`${icon}`} style={{ maxWidth: "80%" }} alt="" />
          </div>
          <div className="service-content">
            <h3>
              <Link to={url}>{title}</Link>
            </h3>
            <p>{text}</p>
            <Link className="service-link" to={url}>
              {action} <i className="fas fa-angle-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSIngleService;
