import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AboutAppoinment = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="appoinment-section pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="appoinment-box white">
                <div className="appoinment-content">
                  <h1>{t("whybios")}</h1>
                  <p>{t("whybiostext")}</p>
                  <ul className="professinals-list pt-30 pb-20">
                    <li>
                      <i className="fa fa-check"></i>
                      <span> {t("goal1")} </span>
                      <p>
                        <b>{t("goal1bas")}</b> {t("goal1text")}
                      </p>
                      <p>
                        <b>{t("goal11bas")}</b> {t("goal11text")}
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span> {t("goal2")} </span>
                      <p>
                        <b>{t("goal2bas")}</b> {t("goal2text")}
                      </p>
                      <p>
                        <b>{t("goal21bas")}</b> {t("goal21text")}
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span> {t("goal3")} </span>
                      <p>
                        <b>{t("goal3bas")}</b> {t("goal3text")}
                      </p>
                      <p>
                        <b>{t("goal31bas")}</b> {t("goal31text")}
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span> {t("goal4")} </span>
                      <p>
                        <b>{t("goal4bas")}</b> {t("goal4text")}
                      </p>
                      <p>
                        <b>{t("goal41bas")}</b> {t("goal41text")}
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span> {t("goal5")} </span>
                      <p>
                        <b>{t("goal5bas")}</b> {t("goal5text")}
                      </p>
                      <p>
                        <b>{t("goal51bas")}</b> {t("goal51text")}
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span> {t("goal6")} </span>
                      <p>
                        <b>{t("goal6bas")}</b> {t("goal6text")}
                      </p>
                      <p>
                        <b>{t("goal61bas")}</b> {t("goal61text")}
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span> {t("goal7")} </span>
                      <p>
                        <b>{t("goal7bas")}</b> {t("goal7text")}
                      </p>
                      <p>
                        <b>{t("goal71bas")}</b> {t("goal71text")}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutAppoinment;
