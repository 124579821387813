import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../../components/Shared/Footer";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";
import DreamPathText from "./DoctorDetailsArea/DreamPathText";

const DreamPath = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title={"DreamPath"} subtitle="Details" />
      <DreamPathText />
      <Footer />
    </>
  );
};

export default DreamPath;
