import React from "react";
import HomeHeader from "./HomeHeader/HomeHeader";
import HomeHeroSection from "./HomeHeroSection/HomeHeroSection";
import HomeServices from "./HomeServices/HomeServices";
import HomeThreeAboutArea from "../../HomeThree/HomeThreeAboutArea/HomeThreeAboutArea";
import Footer from "../../../components/Shared/Footer";
import HomeThreeBlogs from "../../HomeThree/HomeThreeBlogs/HomeThreeBlogs";
import HomeFourBrands from "../../HomeFour/HomeFourBrands/HomeFourBrands";
import AboutSingleCounter from "../../AboutUs/AboutCounter/AboutCounter";
const Home = () => {
  return (
    <>
      <HomeHeader />
      <HomeHeroSection />
      <AboutSingleCounter />
      <HomeThreeAboutArea />
      <HomeServices />
      <HomeThreeBlogs />
      <HomeFourBrands />
      <Footer />
    </>
  );
};

export default Home;
