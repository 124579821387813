import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("footer");
  return (
    <>
      <footer>
        <div className="footer-top primary-bg  pos-rel ">
          <div className="container">
            <div
              className="row align-items-center"
              style={{ position: "relative", zIndex: "9999" }}
            >
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="footer-contact-info footer-contact-info-3 mb-40">
                  <div className="footer-logo mb-35">
                    <Link to="/">
                      <img src="/img/116438644.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="footer-widget mb-40">
                  <div className="footer-title">
                    <h3 style={{ marginBottom: "10px", marginTop: "10px" }}>
                      Menu
                    </h3>
                  </div>
                  <div className="footer-menu footer-menu-2">
                    <ul>
                      <li>
                        <Link to="/about">{t("aboutus")}</Link>
                      </li>
                      <li>
                        <Link to="/pages/cookie-policy">
                          {t("footermenu1")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/pages/information-society-services">
                          {t("footermenu2")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/pages/website-disclosure-text">
                          {t("footermenu3")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/pages/processing-and-protection-of-personal-data">
                          {t("footermenu4")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/pages/personal-data-storage-and-destruction-policy">
                          {t("footermenu5")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="footer-widget h4footer-widget mb-40">
                  <div className="h4footer-social">
                    <ul className="list-inline">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom pt-25 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-copyright footer-copyright-3 text-center">
                  <p>Copyright by@ Kiosk - 2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
