import React from "react";
import SingleSecondShop from "../../../../components/SingleSecondShop/SingleSecondShop";
import SingleShop from "../../../../components/SingleShop/SingleShop";
import { useTranslation } from "react-i18next";

const ShopBanner = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="shop-banner-area pt-120 pb-120">
        <div className="container">
          <div className="row mt-20">
            <div className="col-12">
              <h2>Cell Marque</h2>
              <p>{t("cellicerik")}</p>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    <SingleShop
                      image="36328119.jpg"
                      name="Table"
                      title="A-1-Antichymotrypsin"
                    />
                    <SingleShop
                      image="33142611.jpg"
                      name="Table"
                      title="A-1-Antitrypsin"
                    />
                    <SingleShop
                      image="117393732.jpg"
                      name="Table"
                      title="ACTH"
                    />
                    <SingleShop
                      image="48371259.jpg"
                      name="Table"
                      title="Actin, Muscle Specific (HHF35)"
                    />
                    <SingleShop
                      image="84291579.jpg"
                      name="Table"
                      title="Actin, Smooth Muscle (1A4)"
                    />
                    <SingleShop
                      image="86798918.jpg"
                      name="Table"
                      title="Adenovirus (20/11 & 2/6)"
                    />
                    <SingleShop
                      image="25993586.jpg"
                      name="Table"
                      title="Adipophilin"
                    />
                    <SingleShop
                      image="90666361.jpg"
                      name="Table"
                      title="ALDH1A1 (44)"
                    />
                    <SingleShop
                      image="14472258.jpg"
                      name="Table"
                      title="ALK Protein (ALK-1)"
                    />
                    <SingleShop
                      image="78163574.jpg"
                      name="Table"
                      title="Alpha-Fetoprotein"
                    />
                    <SingleShop
                      image="19743134.jpg"
                      name="Table"
                      title="Alpha-Fetoprotein (EP209)"
                    />
                    <SingleShop
                      image="50281433.jpg"
                      name="Table"
                      title="Androgen Receptor (EP120)"
                    />
                    <SingleShop
                      image="10523811.jpg"
                      name="Table"
                      title="Androgen Receptor (SP107)"
                    />
                    <SingleShop
                      image="63925805.jpg"
                      name="Table"
                      title="Annexin A1 (MRQ-3)"
                    />
                    <SingleShop
                      image="38370159.jpg"
                      name="Table"
                      title="Arginase-1 (EP261)"
                    />
                    <SingleShop
                      image="91092101.jpg"
                      name="Table"
                      title="Arginase-1 (SP156)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="basic-pagination basic-pagination-2 text-center mt-20">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fas fa-angle-double-left"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">01</a>
                  </li>
                  <li>
                    <a href="#">02</a>
                  </li>
                  <li>
                    <a href="#">03</a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fas fa-ellipsis-h"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fas fa-angle-double-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopBanner;
