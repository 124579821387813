import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getSystemLanguage } from "./languageUtils.js";

import userTableEnglish from "../languages/en.json";
import userTableTurkish from "../languages/tr.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      usertable: userTableEnglish,
    },
    tr: {
      usertable: userTableTurkish,
    },
  },
  interpolation: { escapeValue: false },
  fallbackNS: "usertable",
  lng: getSystemLanguage(),
  fallbackLng: "en",
  whitelist: ["en", "tr"],
});

document.documentElement.setAttribute("lang", getSystemLanguage());
console.log("getSystemLanguage", getSystemLanguage());
export default i18n;
