import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../../components/Shared/Sidebar/Sidebar";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const HomeHeader = () => {
  const { t, i18n } = useTranslation("usertable");
  const [show, setShow] = useState(false);
  const [lang, setLang] = useState(Cookies.get("i18next") || "en");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();
  const [loader, setLoader] = useState(true);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 2000);
  }, []);

  return (
    <>
      <div
        style={{
          display: loader ? "block" : "none",
          zIndex: "99999",
          background: "white",
          width: "100%",
          height: "100%",
          position: "fixed",
          textAlign: "center",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              height: "100px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img src="/img/116438644.png" width={150} />
            <img src="/img/tt.gif" width={150} />
            <div className="typewriter">
              <p>Yükleniyor...</p>
            </div>
          </div>
        </div>
      </div>
      <header>
        <div className="top-bar d-none d-md-block">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-xl-12 offset-xl-1 col-lg-12 offset-lg-1 p-2 col-md-12 offset-md-1">
                <div className="header-social-icons f-right d-none d-xl-block">
                  <div className="header-lang pos-rel d-none d-lg-block">
                    <div className="lang-icon">
                      {lang === "tr" ? (
                        <img src="/img/icon/turkey-2.png" alt="" />
                      ) : (
                        <img src="/img/icon/lang.png" alt="" />
                      )}

                      <span>
                        {lang === "tr" ? "TR" : "EN"}{" "}
                        <i className="fas fa-angle-down"></i>
                      </span>
                    </div>
                    <ul className="header-lang-list">
                      {lang === "en" ? (
                        <li>
                          <span
                            onClick={() => {
                              i18n.changeLanguage("tr");
                              Cookies.set("i18next", "tr");
                              setLang("tr");
                            }}
                          >
                            TR
                          </span>
                        </li>
                      ) : (
                        <li>
                          <span
                            onClick={() => {
                              i18n.changeLanguage("en");
                              Cookies.set("i18next", "en");
                              setLang("en");
                            }}
                          >
                            EN
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="header-info">
                  <span>
                    <i className="fas fa-phone"></i> +90 (216) 519 72 01
                  </span>
                  <span>
                    <i className="fas fa-envelope"></i> info@biosmed.com.tr{" "}
                  </span>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-4"></div>
            </div>
          </div>
        </div>

        <div
          className={
            stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"
          }
        >
          <div className="container menu_wrapper">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                <div className="logo logo-circle pos-rel">
                  <Link to="/">
                    <img src="/img/116438644.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                <div className="header-right f-right cbut">
                  <div className="header-top-right-btn f-right">
                    <Link to="/contact" className="primary_btn">
                      {t("contactUs")}
                    </Link>
                  </div>
                </div>
                <div className="header__menu f-right">
                  <nav id="mobile-menu">
                    <ul>
                      <li>
                        <Link to="/">{t("home")}</Link>
                      </li>
                      <li>
                        <Link to="/about">{t("aboutus")}</Link>
                      </li>
                      <li>
                        <Link to="#">{t("ourproducts")}</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/products/cell-marque">CELL MARQUE</Link>
                          </li>

                          <li>
                            <Link to="/products/dreampath">DREAMPATH</Link>
                          </li>
                          <li>
                            <Link to="/products/visia-imaging">
                              VISIA IMAGING
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/services">{t("ourservices")}</Link>
                      </li>
                      <li>
                        <Link to="/refferences">{t("ourreferences")}</Link>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className="side-menu-icon d-lg-none text-end">
                  <button
                    onClick={handleShow}
                    className="side-toggle border-0 bg-transparent"
                  >
                    <i className="fas fa-bars"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeHeader;
