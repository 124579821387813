import React from "react";
import HomeSIngleService from "../../../../components/HomeSIngleService/HomeSIngleService";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
const HomeServices = () => {
  const { t } = useTranslation("usertable");
  return (
    <>
      <section className="servcies-area gray-bg pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-75">
                <div className="section-icon">
                  <img
                    className="section-back-icon"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text pos-rel home_ser_title">
                  <ScrollAnimation
                    animateIn="lightSpeedIn"
                    delay={300}
                    className="animate__slow animate__lightSpeedInRight"
                    duration={1}
                  >
                    <h5>{t("ourproducts")}</h5>
                    <h1>{t("productbaslik")}</h1>
                  </ScrollAnimation>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <ScrollAnimation animateIn="fadeInUp" delay={400}>
            <div className="row">
              <HomeSIngleService
                icon="/img/p-1.jpg"
                title="CELL MARQUE"
                text={t("homecellmarque")}
                action={t("homelearnmore")}
                url={"/products/cell-marque"}
              />
              <HomeSIngleService
                icon="/img/visia/8643778.jpg"
                title="VISIA IMAGING"
                text={t("visiatext")}
                action={t("homelearnmore")}
                url={"/products/visia-imaging"}
              />
              <HomeSIngleService
                icon="/img/p-3.jpg"
                title="DREAMPATH"
                text={t("homedreampath")}
                action={t("homelearnmore")}
                url={"/products/dreampath"}
              />
            </div>
          </ScrollAnimation>
        </div>
      </section>
    </>
  );
};

export default HomeServices;
