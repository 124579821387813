import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ContactFormArea = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="contact-form-area gray-bg pt-100 pb-100">
        <div className="container">
          <div className="form-wrapper">
            <div className="row align-items-center">
              <div className="col-xl-8 col-lg-8">
                <div className="section-title mb-55">
                  <h2>{t("writeus")}</h2>
                </div>
              </div>
            </div>
            <div className="contact-form">
              <form id="contact-form" action="#">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-box user-icon mb-30">
                      <input type="text" name="name" placeholder={t("yname")} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-box email-icon mb-30">
                      <input
                        type="text"
                        name="email"
                        placeholder={t("ymail")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-box phone-icon mb-30">
                      <input
                        type="text"
                        name="phone"
                        placeholder={t("yphone")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-box subject-icon mb-30">
                      <input
                        type="text"
                        name="subject"
                        placeholder={t("ysubject")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-box message-icon mb-30">
                      <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        placeholder={t("ymessage")}
                      ></textarea>
                    </div>
                    <div className="contact-btn text-center">
                      <button
                        className="primary_btn btn-icon ml-0"
                        type="button"
                      >
                        <span>
                          <i className="fas fa-paper-plane"></i>
                        </span>{" "}
                        {t("send")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <p className="ajax-response text-center"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormArea;
