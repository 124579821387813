import React from "react";
import { Link } from "react-router-dom";

const DoctorsTwoSingleTeam = ({ image, name, title }) => {
  return (
    <>
      <div className="col-xl-3 col-lg-3 col-md-6">
        <div className="team-wrapper team-box-2 text-center mb-30">
          <div className="team-thumb">
            <img src={`/img/visia/${image}`} alt="" />
          </div>
          <div className="team-member-info mt-35 mb-25">
            <h3>
              <Link to="#">{name}</Link>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorsTwoSingleTeam;
