import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/Shared/Footer";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const BlogLeftSideBar = () => {
  let { pageslug } = useParams();
  const { t } = useTranslation("usertable");
  const { i18n } = useTranslation("usertable");
  let policienames = [
    {
      "cookie-policy": t("footermenu1"),
      "information-society-services": t("footermenu2"),
      "website-disclosure-text": t("footermenu3"),
      "processing-and-protection-of-personal-data": t("footermenu4"),
      "personal-data-storage-and-destruction-policy": t("footermenu5"),
    },
  ];
  const [lang, setLang] = React.useState(i18n.language);
  const [html, setHTML] = React.useState({ __html: "" });
  React.useEffect(() => {
    i18n.on("languageChanged", (lng) => setLang(lng));
    async function createMarkup() {
      let response;
      response = await fetch(
        `${window.location.origin}/admin/api.php?topic=${pageslug}&lang=${i18n.language}&tipo=html`
      );
      const backendHtmlString = await response.text();

      console.log(backendHtmlString);
      return { __html: backendHtmlString };
    }
    createMarkup().then((result) => setHTML(result));
  }, [lang, pageslug]);
  console.log("pageslug", policienames);
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title={policienames[0][pageslug]} />
      <section className="blog-area pt-120 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div dangerouslySetInnerHTML={html}></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogLeftSideBar;
