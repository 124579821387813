import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ServicesDetailsArea = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="service-details-area pt-120 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <article className="service-details-box">
                <div className="service-details-thumb mb-80">
                  <img className="img" src="img/patho.jpg" alt="" />
                </div>
              </article>
            </div>
            <div className="col-xl-5 col-lg-4">
              <div className="service-widget mb-50">
                <div className="widget-title-box mb-30">
                  <h3 className="widget-title">{t("ourservices")}</h3>
                </div>
                <div className="more-service-list">
                  <ul>
                    <li>
                      <Link to="#">
                        <div className="more-service-icon">
                          <img src="img/services/more-ser-1.png" alt="" />
                        </div>
                        <div className="more-service-title">{t("kurulum")}</div>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <div className="more-service-icon">
                          <img src="img/services/more-ser-6.png" alt="" />
                        </div>
                        <div className="more-service-title">
                          {t("aplikasyon")}
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <div className="more-service-icon">
                          <img src="img/services/mor-ser-7.png" alt="" />
                        </div>
                        <div className="more-service-title">
                          {t("servishiz")}
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <div className="more-service-icon">
                          <img src="img/services/mor-ser-8.png" alt="" />
                        </div>
                        <div className="more-service-title">{t("satson")}</div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesDetailsArea;
