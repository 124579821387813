import React from "react";
import { Link } from "react-router-dom";
import HomeThreeSingleBlog from "../../../components/HomeThreeSingleBlog/HomeThreeSingleBlog";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
const HomeThreeBlogs = () => {
  const { t } = useTranslation("usertable");
  const { i18n } = useTranslation("usertable");
  const [lang, setLang] = React.useState(i18n.language);
  const [html, setHTML] = React.useState([]);
  React.useEffect(() => {
    i18n.on("languageChanged", (lng) => setLang(lng));
    async function createMarkup() {
      let response;
      response = await fetch(
        `${window.location.origin}/admin/api.php?topic=miniblog&lang=${i18n.language}`
      );
      const backendHtmlString = await response.json();

      console.log(backendHtmlString);
      return backendHtmlString;
    }
    createMarkup().then((result) => setHTML(result));
  }, [lang]);
  return (
    <>
      <section className="latest-news-area pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7">
              <div className="section-title pos-rel mb-75">
                <div className="section-icon">
                  <img
                    className="section-back-icon back-icon-left"
                    src="img/icon/pathology.png"
                    alt=""
                    width={100}
                    style={{ opacity: 0.1 }}
                  />
                </div>
                <div className="section-text pos-rel">
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    delay={300}
                    className="animate__slow "
                    duration={1}
                  >
                    <h1>{t("homebizdenhaberler")}</h1>
                  </ScrollAnimation>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5 d-none d-lg-block"></div>
          </div>
          <div className="row">
            {html.map((item, index) => (
              <HomeThreeSingleBlog
                title={lang == "en" ? item.baslik_en : item.baslik}
                image={"/admin/" + item.resim}
                icerik={lang == "en" ? item.icerik_en : item.icerik}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeThreeBlogs;
