import React from "react";

const BrandLogo = ({ imagePath }) => {
  return (
    <>
      <div className="single-brand">
        <img src={`${imagePath}`} alt="" />
      </div>
    </>
  );
};

export default BrandLogo;
