import React from "react";
import { Link } from "react-router-dom";

const CommonPageHeader = ({ title, subtitle }) => {
  return (
    <>
      <section className="breadcrumb-bg pt-200 pb-80 page_header_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-9">
              <div className="page-title">
                <h1>{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonPageHeader;
