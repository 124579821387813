import React from "react";
import DoctorsTwoSingleTeam from "../../../../components/DoctorsTwoSingleTeam/DoctorsTwoSingleTeam";

const DoctorsTwoTeamArea = () => {
  return (
    <>
      <section className="team-area pt-115 pb-90">
        <div className="container">
          <div className="row">
            <DoctorsTwoSingleTeam image="8643778.jpg" name="NAVIGO" />
            <DoctorsTwoSingleTeam image="66259834.jpg" name="NAVIFIVE" />
            <DoctorsTwoSingleTeam image="115790617.jpg" name="NAVIFISH" />
            <DoctorsTwoSingleTeam image="121293200.jpg" name="NAVIFLUO" />
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorsTwoTeamArea;
