import React, { useState, useEffect } from "react";
import AboutSingleCounter from "../../../components/AboutSingleCounter/AboutSingleCounter";
import { useTranslation } from "react-i18next";

const AboutCounter = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="counter-wraper pt-120 pb-90 gray-bg">
        <div className="container">
          <div className="row text-center">
            <AboutSingleCounter
              icon="99"
              counter="30"
              title={t("aboutcounter1")}
            />
            <AboutSingleCounter
              icon="2"
              counter="158"
              title={t("aboutcounter2")}
            />
            <AboutSingleCounter
              icon="3"
              counter="60"
              title={t("aboutcounter3")}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutCounter;
