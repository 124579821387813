import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import AllContext from "./context/AllContext";
import AboutUs from "./pages/AboutUs/AboutUs";
import Appointment from "./pages/Appointment/Appointment";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import BlogLeftSideBar from "./pages/BlogLeftSideBar/BlogLeftSideBar";
import BlogNoSideBar from "./pages/BlogNoSideBar/BlogNoSideBar";
import Blogs from "./pages/Blogs/Blogs";
import BlogThreeColMasonry from "./pages/BlogThreeColMasonry/BlogThreeColMasonry";
import BlogThreeColumn from "./pages/BlogThreeColumn/BlogThreeColumn";
import BlogTwoColumn from "./pages/BlogTwoColumn/BlogTwoColumn";
import BlogTwoColumnMasonry from "./pages/BlogTwoColumnMasonry/BlogTwoColumnMasonry";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import Contact from "./pages/Contact/Contact";
import DetailsAudio from "./pages/DetailsAudio/DetailsAudio";
import DetailsGallery from "./pages/DetailsGallery/DetailsGallery";
import DetailsLeftSideBar from "./pages/DetailsLeftSideBar/DetailsLeftSideBar";
import DetailsVideo from "./pages/DetailsVideo/DetailsVideo";
import DoctorDetails from "./pages/Doctors/DoctorDetails/DoctorDetails";
import DoctorsOne from "./pages/Doctors/DoctorsOne/DoctorsOne";
import DoctorsTwo from "./pages/Doctors/DoctorsTwo/DoctorsTwo";
import Home from "./pages/Home/Home/Home";
import HomeThree from "./pages/HomeThree/HomeThree";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import HomeFour from "./pages/HomeFour/HomeFour";
import Login from "./pages/Login/Login";
import PortfolioSlider from "./pages/PortfolioSlider/PortfolioSlider";
import PortfolioThreeCol from "./pages/PortfolioThreeCol/PortfolioThreeCol";
import PortfolioTwoColumn from "./pages/PortfolioTwoColumn/PortfolioTwoColumn";
import Register from "./pages/Register/Register";
import ServicesDetails from "./pages/Services/ServicesDetails/ServicesDetails";
import ServicesOne from "./pages/Services/ServicesOne/ServicesOne";
import ServicesTwo from "./pages/Services/ServicesTwo/ServicesTwo";
import ShopDetails from "./pages/ShopDetails/ShopDetails/ShopDetails";
import ShopPage from "./pages/ShopPage/ShopPage/ShopPage";
import ShoppingCart from "./pages/ShoppingCart/ShoppingCart";
import WishList from "./pages/wishList/WishList";
import HomeFive from "./pages/HomeFive/HomeFive";
import "./App.css";
import NotFound from "./pages/NotFound/NotFound";
import i18n from "./utils/i18n";
import DreamPath from "./pages/Doctors/DoctorDetails/Dreampath";

function App() {
  return (
    <>
      <AllContext>
        <BrowserRouter>
          <ScrollTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products/dreampath" element={<DreamPath />} />
            <Route path="/servicesTwo" element={<ServicesTwo />} />
            <Route path="/services" element={<ServicesDetails />} />
            <Route path="/refferences" element={<DoctorsOne />} />
            <Route path="/kvkk" element={<DoctorsTwo />} />
            <Route path="/products/visia-imaging" element={<DoctorDetails />} />
            <Route path="/products/cell-marque" element={<ShopPage />} />

            <Route path="/about" element={<AboutUs />} />
            <Route path="/pages/:pageslug" element={<BlogLeftSideBar />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AllContext>
    </>
  );
}

export default App;
