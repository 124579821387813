import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DoctorsTwoTeamArea from "../../DoctorsTwo/DoctorsTwoTeamArea/DoctorsTwoTeamArea";
const DreamPathText = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="doctor-details-area pt-115 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 ">
                    <div className="appoinment-box white">
                      <div className="appoinment-content">
                        <h1>DreamPath</h1>

                        <ul className="professinals-list pt-30 pb-20">
                          <li>
                            <i className="fa fa-check"></i>
                            {t("dreamtext")}
                          </li>
                          <li>
                            <i className="fa fa-check"></i>
                            {t("dreamtext2")}
                          </li>
                          <li>
                            <i className="fa fa-check"></i>
                            {t("dreamtext3")}
                          </li>
                          <li>
                            <i className="fa fa-check"></i>
                            {t("dreamtext4")}
                          </li>
                          <li>
                            <i className="fa fa-check"></i>
                            {t("dreamtext5")}
                          </li>
                          <li>
                            <i className="fa fa-check"></i>
                            {t("dreamtext6")}
                          </li>
                          <li>
                            <i className="fa fa-check"></i>
                            {t("dreamtext7")}
                          </li>
                          <li>
                            <i className="fa fa-check"></i>
                            {t("dreamtext8")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <img src="/img/dream.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DreamPathText;
