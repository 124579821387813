import React from "react";
import { Link } from "react-router-dom";
import useGlobalContext from "../../hooks/useGlobalContext";
import { useTranslation } from "react-i18next";

const HomeHeroSingleSlide = ({ bg_className }) => {
  const { t } = useTranslation();
  const videoPopup = useGlobalContext();
  const { setIsOpen } = videoPopup;
  return (
    <>
      <div
        className={`single-slider slider-height d-flex slider_bg_${bg_className}`}
      >
        <div className="container slidecont">
          <div className="row">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="hero-text">
                <div className="hero-slider-caption ">
                  <h5 className="text-white">{t("slogan1")}</h5>
                  <h1 className="text-white">{t("slogan2")}</h1>
                  <p className="text-white">{t("slogan3")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHeroSingleSlide;
