import React from "react";
import BrandLogo from "../../../components/BrandLogo/BrandLogo";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
const HomeFourBrands = () => {
  const { t } = useTranslation();
  // settings
  const settings = {
    autoplay: false,
    autoplaySpeed: 10000,
    dots: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section className="h4brand-area pos-rel pb-130">
        <div className="container">
          <div className="row ">
            <div className="section-text p-5 text-center pos-rel">
              <h3>{t("homeblogbaslik")}</h3>
            </div>
            <Slider className="brand-active" {...settings}>
              <BrandLogo imagePath="/img/104906014.jpg" />
              <BrandLogo imagePath="/img/83089713.jpg" />
              <BrandLogo imagePath="/img/97973626.jpg" />
              <BrandLogo imagePath="/img/120228852.jpg" />
              <BrandLogo imagePath="/img/53764582.jpg" />
              <BrandLogo imagePath="/img/20664313.jpg" />
              <BrandLogo imagePath="/img/70677348.jpg" />
              <BrandLogo imagePath="/img/96329068.jpg" />
              <BrandLogo imagePath="/img/96378046.jpg" />
              <BrandLogo imagePath="/img/38673451.jpg" />
              <BrandLogo imagePath="/img/97272852.jpg" />
              <BrandLogo imagePath="/img/103965997.jpg" />
              <BrandLogo imagePath="/img/98783660.jpg" />
              <BrandLogo imagePath="/img/7330768.jpg" />
              <BrandLogo imagePath="/img/122796473.jpg" />
              <BrandLogo imagePath="/img/47314447.jpg" />
              <BrandLogo imagePath="/img/90114408.jpg" />
              <BrandLogo imagePath="/img/27800151.jpg" />
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeFourBrands;
