import React from "react";

const ContactMap = () => {
  return (
    <>
      <section className="map-area">
        <div style={{ width: "100%" }}>
          <iframe
            title="Contact"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3013.72393161851!2d29.131529076201367!3d40.94371727135972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac6836a1b510f%3A0x88eb28d4fe6c6187!2sBios%20Biomedikal%20Sistemler!5e0!3m2!1str!2str!4v1727039510859!5m2!1str!2str"
            style={{ width: "100%" }}
            height={600}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default ContactMap;
