import React from "react";
import { useTranslation } from "react-i18next";

const CommonSectionArea = ({ area_header }) => {
  const { t } = useTranslation();

  return (
    <>
      <section className="about-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5">{area_header}</div>
            <div className="col-xl-6 col-lg-7">
              <div className="about-right-side pt-55 mb-30">
                <div className="about-title mb-20">
                  <h5>{t("aboutus")}</h5>
                  <h1>{t("aboutaltbas")}</h1>
                </div>
                <div className="about-text mb-50">
                  <p>{t("abouticerik")}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12">
              <div className="our-destination mt-5 d-flex">
                <div className="single-item mb-30">
                  <div className="mv-icon f-left">
                    <img src="img/about/destination-icon-1.png" alt="" />
                  </div>
                  <div className="mv-title fix">
                    <h3>{t("ourmission")}</h3>
                    <p>{t("missiontext")}</p>
                  </div>
                </div>
                <div className="single-item">
                  <div className="mv-icon f-left">
                    <img src="img/about/destination-icon-2.png" alt="" />
                  </div>
                  <div className="mv-title fix">
                    <h3>{t("ourvision")}</h3>
                    <p>{t("visiontext")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonSectionArea;
